import React, { useEffect, useState, useCallback } from 'react'
import { SidebarMenuReport } from './SidebarMenuReport'
import EventBus from '../../../../../libraries/react-chatbot/src/utils'
import IPConfig from '../../../../../app/store/IPConfig'
import { getAuth } from '../../../../../app/modules/auth'
import axios from 'axios'

type PropsType = {
  wsString: string
}

const SidebarMenuMain = ({ wsString }: PropsType) => {
  const apiUrl = IPConfig()
  const wsUrl = IPConfig("ws")

  const [reports, setReports] = useState<any[]>([])
  const [userId, setUserId] = useState<string | null>(null)
  const [activeReport, setActiveReport] = useState<number | null>(null)
  const [ws, setWs] = useState<WebSocket | null>(null)

  useEffect(() => {
    const user = getAuth()
    if (user) {
      setUserId(user.data.id)
    }
  }, [])

  useEffect(() => {
    if (userId) {
      fetchReports()
      establishWebSocketConnection()
    }

    return () => {
      if (ws) {
        ws.close()
      }
    }
  }, [userId, wsString])

  const establishWebSocketConnection = useCallback(() => {
    if (userId && wsString) {
      const wsConnectionUrl = `${wsUrl}/${wsString}?user_id=${userId}`
      const newWs = new WebSocket(wsConnectionUrl)

      newWs.onopen = () => console.log('WebSocket connection established.')
      newWs.onmessage = handleWebSocketMessage
      newWs.onclose = handleWebSocketClose
      newWs.onerror = (error) => console.error('WebSocket error:', error)

      setWs(newWs)
    }
  }, [userId, wsString, wsUrl])

  const handleWebSocketMessage = (event: MessageEvent) => {
    const response = JSON.parse(event.data)
    if (response.data) {
      setReports(response.data)
      if (activeReport !== null) {
        dispatchReport(response.data[activeReport], activeReport)
      } else {
        setActiveReport(0)
        dispatchReport(response.data[0], 0)
      }
    } else {
      setReports([])
    }
  }

  const handleWebSocketClose = () => {
    console.log('WebSocket connection closed. Attempting to reconnect...')
    setTimeout(establishWebSocketConnection, 5000)
  }

  const onClickReport = (report: any, index: number) => {
    dispatchReport(report, index)
  }

  const dispatchReport = (report: any, index: number) => {
    if (report) {
      setActiveReport(index)
      EventBus.publish('selected-report-name', 'Pitch Readiness Report')
      EventBus.publish('selected-report-content', '')
      EventBus.publish('selected-score-content', '')
      EventBus.publish('selected-source-id', '')
      EventBus.publish('selected-job', '')

      if (report.status.name !== "completed") {
        EventBus.publish('loading-report', true)
      }

      if (report.status.name === "cancelled") {
        EventBus.publish('report-status', 'cancelled')
        EventBus.publish('cancelled-note', report.log)
      } else {
        EventBus.publish('report-status', report.status.name)
      }

      if (report.saved_documents && report.saved_documents.length > 0) {
        if (report.saved_documents[0]) {
          EventBus.publish('selected-report-name', report.saved_documents[0].name)
          EventBus.publish('selected-report-content', report.saved_documents[0].id)
        }

        if (report.saved_documents[1]) {
          EventBus.publish('selected-score-content', report.saved_documents[1].id)
        }
      }

      if (report.source_documents) {
        const sourceDocumentsArray = JSON.parse(report.source_documents)
        if (sourceDocumentsArray && sourceDocumentsArray[0]) {
          EventBus.publish('selected-source-id', sourceDocumentsArray[0])
        }
      } else {
        EventBus.publish('selected-source-id', '')
      }

      if (report.id) {
        EventBus.publish('selected-job', report.id)
      } else {
        EventBus.publish('selected-job', '')
      }

      if (report.input_variables && report.input_variables.agent_persona) {
        EventBus.publish('selected-persona-name', report.input_variables.agent_persona)
      } else {
        EventBus.publish('selected-persona-name', '')
      }

      if (report.input_variables && report.input_variables.agent_avatar) {
        EventBus.publish('selected-persona-avatar', report.input_variables.agent_avatar)
      } else {
        EventBus.publish('selected-persona-avatar', '')
      }
    } else {
      EventBus.publish('loading-report', true)
    }
  }

  const fetchReports = async () => {
    if (!userId) return

    try {
      const response = await axios.get(`${apiUrl}/get-report`, {
        params: { user_id: userId },
      })

      if (response.data.data) {
        setReports(response.data.data)
        setActiveReport(0)
        dispatchReport(response.data.data[0], 0)
      } else {
        setReports([])
      }
    } catch (error) {
      console.error('Unable to fetch reports:', error)
    }
  }

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section alt-white text-uppercase fs-7 ls-1 fw-bolder">
            Product Requested
          </span>
        </div>
      </div>

      {reports.length > 0 ? (
        reports.map((report, index) => (
          <SidebarMenuReport
            key={index}
            to="#"
            icon="clipboard"
            status={`${report.status.name === 'failed' ? 'processing' : report.status.name}`}
            title={`${report.date_created}`}
            reportName="Pitch Readiness Report"
            companyName={wsString === "websocket-investor-report" ? (report.input_variables.company_name || "") : ""}
            fontIcon="bi-layers"
            onClick={() => onClickReport(report, index)}
            active={activeReport === index}
          />
        ))
      ) : (
        <div className='text-muted'>
          <p className='ps-4'>Reports are currently pending...</p>
        </div>
      )}
    </>
  )
}

export { SidebarMenuMain }
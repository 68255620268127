/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserModel, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import IPConfig from '../../../store/IPConfig'
import axios from 'axios'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  password_confirmation: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function AccountSetup() {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState("")

  const {saveAuth, setCurrentUser} = useAuth()
  const { search } = useLocation();
  const apiUrl = IPConfig()
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(search);
    const tokenResponse = params.get('token');
    if (tokenResponse) {
      setToken(tokenResponse);
    }
  }, [search]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (token) {
          const formData = new FormData();
          formData.append('token', token);
          formData.append('password', values.password);

          const response = await axios.post(`${apiUrl}/complete-register`, formData);

          if (response.data.status === 'success') {
            setStatus('Account setup successfully! Please wait while we redirect you to the login page.');

            setTimeout(() => {
              navigate('/auth/login');
            }, 2000);
          } else {
            if (response.data.message) {
              setStatus('Failed to complete account setup. ' + response.data.message);
            } else {
              setStatus('Failed to complete account setup. Please try again.');
            }
          }
        }
      } catch (error) {
        console.error(error);
        setStatus('Failed to update workspace. ' + error);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Logo */}
        <Link to='/'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/io_logo2.png')} style={{ width: `109px`, marginBottom: `20px` }} />
        </Link>
        {/* end::Logo */}

        <h1 className='text-light fw-bolder mb-3'>Account Finalization</h1>
        <div className='text-white fw-semibold fs-6'>Setup your new password</div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-info'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-light fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-white fw-bold'>
            <div className='fv-help-block'>
              <span className='text-white fw-bold' role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-light fs-6 mb-0'>Confirm Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container text-white fw-bold'>
            <div className='fv-help-block'>
              <span className='text-white fw-bold' role='alert'>{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

    </form>
  )
}

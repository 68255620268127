import React, {FC, useEffect, useRef, useState} from 'react'
import {Step2InvestorReprocess} from './steps/Step2InvestorReprocess'
import {Step3} from './steps/Step3'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import {Link} from 'react-router-dom'
import IPConfig from '../../store/IPConfig'
import clsx from 'clsx'
import {getAuth} from '../auth'
import axios from 'axios'

const InvestorReprocessWizard: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [stepIncomplete, setStepIncomplete] = useState(false)
  const [documentUploaded, setDocumentUploaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [responseError, setResponseError] = useState('')
  const [responseSuccess, setResponseSuccess] = useState('')
  const [selectedAgentIndex, setSelectedAgentIndex] = useState<number | null>(null)
  const [filesToAdd, setFilesToAdd]: any = useState([])
  const [formikValues, setFormikValues]: any = useState([])
  const [userId, setUserId] = useState('')
  const [documentId, setDocumentId] = useState('')

  const apiUrl = IPConfig('metis')

  useEffect(() => {
    const user = getAuth();
    if (user) {
      setUserId(user.data.id)
    }
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === 1)
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      const query = new URLSearchParams(window.location.search)
      const personaName = window.localStorage.getItem('wizardPersonaName');

      if (stepper.current?.currentStepIndex === 0) {
        query.set('registration', 'true')

        if (personaName && typeof selectedAgentIndex !== 'undefined' && selectedAgentIndex !== null) {
          query.set('persona', personaName)
        }
      }

      if (
        stepper.current.currentStepIndex === 0 &&
        (typeof formikValues === 'undefined' || !formikValues)
      ) {
        setStepIncomplete(true)
      } else if (
        stepper.current.currentStepIndex === 1 &&
        typeof formikValues !== 'undefined' &&
        formikValues
      ) {
        setStepIncomplete(false)
        if (documentUploaded) {
          makePayment()
        } else {
          submitRegistration(stepper)
        }
      } else {
        setStepIncomplete(false)
        const newUrl = `${window.location.pathname}?${query.toString()}`;
        window.history.pushState({}, '', newUrl);
        stepper.current.goNext()
      }
    }

    setSubmitButton(stepper.current.currentStepIndex === 1)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (!stepper.current) {
      return;
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      stepper.current?.goto(2);

      const processDocument = async () => {
        try {
          const user = window.localStorage.getItem('selectedUserId');
          const document = window.localStorage.getItem('selectedDocumentId');
          const responsePaymentId = window.localStorage.getItem('responsePaymentId');

          const formData = new FormData();
          formData.append('pdf_file_id', document || '');
          formData.append('user_id', user || '');
          formData.append('payment_id', responsePaymentId || '');

          const response = await axios.post(`${apiUrl}/process-investor-new-doc`, formData);

          if (response.data.status === 'fail') {
            setResponseError(response.data.message || 'Your file could not be processed');
            setResponseSuccess('');
          } else if (response.data.status === 'success') {
            setResponseError('');
            setResponseSuccess('Your file has been submitted for processing');
            window.localStorage.setItem('processedCompanyId', response.data.company_id);
          }
        } catch (error) {
          console.error(error);
          setResponseError('Your file could not be processed');
        } finally {
          setLoading(false);
          setLoadingPayment(false);
        }
      };

      processDocument();
    }

    if (query.get('canceled')) {
      stepper.current?.goto(1);
      setResponseError('Your order has been cancelled');
    }
  }, []);

  const makePayment = async () => {
    setLoading(false);
    setLoadingPayment(true);

    const url = `${apiUrl}/create-checkout-session`;
    const user = window.localStorage.getItem('selectedUserId');

    const formData = new FormData();
    formData.append('investor', 'true');
    formData.append('registered', 'true');
    formData.append('user_id', user || '');

    try {
      const response = await axios.post(url, formData);

      if (response.data.payment) {
        window.localStorage.setItem('responsePaymentId', response.data.payment);
      }
      if (response.data.data) {
        window.location.href = response.data.data;
      } else {
        // TODO: Handle case when response.data does not contain expected properties
        console.error("Unexpected response format", response.data);
      }
    } catch (error) {
      console.error('Unable to fetch -', error);
    } finally {
      setLoading(false);
      setLoadingPayment(false);
    }
  };

  const submitRegistration = async (stepper) => {
    setLoading(true);
    setLoadingPayment(false);

    const file = filesToAdd[0];

    // Validation checks
    if (!file || !file.name) {
      setResponseError('Please select a file for upload');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file_name', file.name);
    formData.append('file_label', file.name);
    formData.append('file_description', file.name);
    formData.append('file', file, file.name);
    formData.append('temp_metis', 'true');

    if (userId) {
      formData.append('user_id', userId);
      window.localStorage.setItem('selectedUserId', userId);
    }

    try {
      const response = await axios.post(`${apiUrl}/upload`, formData);

      if (response.data.status === 'fail') {
        setResponseError(response.data.message || 'Your file could not be processed');
        setResponseSuccess('');
      } else if (response.data.status === 'success') {
        setResponseError('');
        setResponseSuccess('Your file has been uploaded successfully.');

        if (response.data.document_id) {
          setDocumentId(response.data.document_id);
          window.localStorage.setItem('selectedDocumentId', response.data.document_id);
        }

        setDocumentUploaded(true);
      }
    } catch (error) {
      console.error(error);
      setResponseError('Your file could not be processed');
    } finally {
      setLoading(false);
      setLoadingPayment(false);
    }
  };

  return (
    <div ref={stepperRef} className="stepper stepper-links row" id="kt_create_account_stepper">
      <div className="col-12 d-flex w-100">
        {/* begin::Logo */}
        <div className={'fs-7qx'}>
          <Link to="/reports">
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/alt-logo-dark.png')}
              className="mt-8 ms-6 float-start"
              style={{width: `180px`}}
            />
          </Link>
        </div>
        {/* end::Logo */}
      </div>

      <div className="col-lg-12">
        <ol className="d-block stepper-nav step-indicator mt-6">
          <li className="stepper-entity fs-4 current" data-kt-stepper-element="nav">
            <div className="step"></div>
            <div className="stepper-title text-light caption hidden-xs hidden-sm">
              step <span>1</span>: <span>upload pitch deck</span>
            </div>
          </li>
          <li className="stepper-entity fs-4" data-kt-stepper-element="nav">
            <div className="step"></div>
            <div className="stepper-title text-light caption hidden-xs hidden-sm">
              step <span>2</span>: <span>make payment</span>
            </div>
          </li>
        </ol>
      </div>

      <div
        className={clsx('col-12', {
          'd-none': !stepIncomplete,
        })}
      >
        <div className="mt-6 ms-8 me-4 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Please complete the current step before continuing
          </div>
        </div>
      </div>

      <Formik initialValues={initValues} onSubmit={submitStep}>
        {() => (
          <Form className="mx-auto w-100 pt-15 pb-10 ps-12 pe-4" id="kt_create_account_form">
            <div className="current" data-kt-stepper-element="content">
              <Step2InvestorReprocess
                responseError={responseError}
                responseSuccess={responseSuccess}
                setFormikValues={setFormikValues}
                setFilesToAdd={setFilesToAdd}
                filesToAdd={filesToAdd}
                stepper={stepper}
                loading={loading}
                documentUploaded={documentUploaded}
              />
            </div>

            <div data-kt-stepper-element="content">
              <Step3 />
            </div>

            {(stepper?.current?.currentStepIndex === 0 ||
              stepper?.current?.currentStepIndex === 1) && (
              <div className="d-flex flex-stack pt-15">
                <div className="mr-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-lg alt-bg-purple text-white me-3"
                    data-kt-stepper-action="previous"
                  >
                    <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                    Back
                  </button>
                </div>

                <div>
                  <button
                    disabled={stepper?.current?.currentStepIndex === 1 && !documentUploaded}
                    type="submit"
                    className="btn btn-lg alt-bg-purple text-white me-3"
                  >
                    {!loadingPayment && (
                      <div>
                        <div className="indicator-label float-start mt-0">
                          <span id="nextButtonLabel">
                            {stepper?.current?.currentStepIndex === 0 && 'Continue'}
                            {stepper?.current?.currentStepIndex === 1 && documentUploaded && 'Make Payment'}
                            {stepper?.current?.currentStepIndex === 1 && !documentUploaded && 'Make Payment'}
                          </span>
                        </div>
                        <div className="float-end" style={{paddingTop: '2px'}}>
                          <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                        </div>
                      </div>
                    )}
                    {loadingPayment && (
                      <span className="indicator-progress" style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {InvestorReprocessWizard}

import React, { useEffect } from 'react'

interface VerbosePanelProps {
  verboseLogs: string[];
  setVerboseLogs: (logs: string[]) => void;
}

const VerbosePanel: React.FC<VerbosePanelProps> = ({ verboseLogs, setVerboseLogs }) => {
  const eventTypes = {
    on_chain_start: 'success',
    on_prompt_start: 'info',
    on_prompt_end: 'warning',
    on_chat_model_start: 'success',
    on_chat_model_end: 'danger',
    on_chain_end: 'danger',
    on_tool_start: 'info',
    on_tool_end: 'warning',
    on_tool_error: 'danger',
  }

  useEffect(() => {
    return () => {
      // Clear the verbose logs when the component is unmounted
      setVerboseLogs([])
    }
  }, [setVerboseLogs])

  const formatTime = (timeString: string) => {
    const date = new Date(timeString)
    return date.toLocaleTimeString('en-US', {hour12: false})
  }

  const clearLogs = () => {
    setVerboseLogs([])
  }

  const isValidJSON = (str: string) => {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }

  const renderContent = (content: any): React.ReactNode => {
    const extractText = (item: any): string | null => {
      if (typeof item === 'string') return item;
      if (item && typeof item.text === 'string') return item.text;
      if (item && typeof item.content === 'string') return item.content;
      if (Array.isArray(item)) {
        for (const subItem of item) {
          const text = extractText(subItem);
          if (text) return text;
        }
      }
      return null;
    };

    const text = extractText(content);

    if (typeof content === 'string') {
      return isValidJSON(content) ? (
        <pre className="chat-background p-3 mt-2 rounded alt-white" style={{ maxWidth: '100%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        <code style={{ display: 'block', overflow: 'auto' }}>
          {JSON.stringify(JSON.parse(content), null, 2)}
        </code>
      </pre>
      ) : (
        <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>{content}</span>
      );
    } else if (typeof content === 'object' && content !== null) {
      return (
        <pre className="chat-background p-3 mt-2 rounded alt-white" style={{ maxWidth: '100%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        <code style={{ display: 'block', overflow: 'auto' }}>
          {JSON.stringify(content, null, 2)}
        </code>
      </pre>
      );
    } else if (text !== null) {
      return <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>{text}</span>;
    } else {
      return <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>{String(content)}</span>;
    }
  };

  const renderLogData = (log: any) => {
    switch (log.event) {
      case 'on_chain_start':
      case 'on_prompt_start':
      case 'on_prompt_end':
        return (
          <div>
            <span className="fw-bold pe-2">Input:</span>
            {renderContent(log.data.input?.user_input?.content || log.data.input?.user_input || '')}
          </div>
        );
      case 'on_tool_start':
        return (
          <div>
            <span className="fw-bold pe-2">Input:</span>
            {renderContent(log.data.input?.args)}
          </div>
        );
      case 'on_tool_end':
        return (
          <div>
            <span className="fw-bold pe-2">Output:</span>
            {renderContent(log.data.output?.content)}
          </div>
        );
      case 'on_tool_error':
        return (
          <div>
            <span className="fw-bold pe-2">Output:</span>
            {renderContent(log.data.output?.content)}
          </div>
        );
      case 'on_chat_model_start':
        return (
          <div>
            {renderContent(log.data.input?.messages || log.data.input?.user_input || 'No input found')}
          </div>
        );
      case 'on_chat_model_end':
      case 'on_chain_end':
        return (
          <div>
            <span className="fw-bold pe-2">Output:</span>
            {renderContent(log.data.output?.content)}
          </div>
        );
      default:
        return <div>{renderContent(log.data)}</div>;
    }
  };

  return (
    <div className="card w-100 h-100 rounded-0 overflow-y-scroll chat-background border-0 alt-white" style={{width: '350px'}}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title alt-white">Verbose</h3>
        <button className="btn btn-sm btn-light-primary" onClick={clearLogs}>
          Clear Logs
        </button>
      </div>
      <div className="card-body">
        {/* begin::Timeline */}
        <div className="timeline-label">
          {verboseLogs.map((log: any, index) => (
            <div key={index}>
              {typeof log === 'object' && (
                <div className="timeline-item">
                  {/* begin::Label */}
                  <div className="timeline-label fw-bold text-gray-600 fs-6">
                    {log.time ? formatTime(log.time) : 'N/A'}
                  </div>
                  {/* end::Label */}

                  {/* begin::Badge */}
                  <div className="timeline-badge">
                    <i className={`fa fa-genderless text-${eventTypes[log.event]} fs-1`}></i>
                  </div>
                  {/* end::Badge */}

                  {/* begin::Text */}
                  <div className="timeline-content">
                    <div className="d-flex align-items-start">
                      <span
                        className="fw-bold text-gray-800 ps-3"
                      >
                        {log.event === 'on_chain_start' && (
                          <div>Chat Initialized</div>
                        )}
                        {log.event === 'on_chain_end' && (
                          <div>Chat Completed</div>
                        )}

                        {log.event === 'on_chat_model_start' && (
                          <div>Chat Model Initialized</div>
                        )}
                        {log.event === 'on_chat_model_end' && (
                          <div>Chat Model Completed</div>
                        )}

                        {log.event === 'on_tool_start' && (
                          <div>Tool Initialized ({log.name})</div>
                        )}
                        {log.event === 'on_tool_end' && (
                          <div>Tool Completed ({log.name})</div>
                        )}
                        {log.event === 'on_tool_error' && (
                          <div>Tool Error ({log.name})</div>
                        )}
                      </span>
                    </div>
                    <div>
                      <div className="ps-3 pt-3 pb-3">
                        {log.data && renderLogData(log)}
                      </div>
                    </div>
                  </div>
                  {/* end::Text */}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* end::Timeline */}
      </div>
    </div>
  )
}

export default VerbosePanel
import React, { FC, useEffect, useState, useRef } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { IAgentDetails } from '../../../../../app/models/AgentModel'
import { useLocation } from 'react-router-dom'
import { Chatbot, VerbosePanel } from '../../index'
import IPConfig from '../../../../../app/store/IPConfig'
import EventBus from '../../utils'
import axios from 'axios'

const DrawerMessenger: FC = () => {
  const apiUri = IPConfig()
  const location = useLocation()

  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [agentArray, setAgentArray] = useState<IAgentDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [selectedAgent, setSelectedAgent] = useState<IAgentDetails | null>(null);
  const [isVerbosePanelOpen, setIsVerbosePanelOpen] = useState<boolean>(false);
  const [verboseLogs, setVerboseLogs] = useState<string[]>([]);
  const [isResizing, setIsResizing] = useState(false);
  const [verbosePanelWidth, setVerbosePanelWidth] = useState(400);
  const drawerRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);

  const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)
  const [selectedCollectionId, setSelectedCollectionId] = useState<string | undefined>(undefined)

  const isCompanyPage =
    location.pathname.includes('companies/overview') ||
    location.pathname.includes('companies/original') ||
    location.pathname.includes('companies/investor') ||
    location.pathname.includes('companies/document/view');
  const isCollectionPage = location.pathname.includes('documents/view')
  const isDashboardPage = location.pathname.includes('dashboard')
  const isReportPage = location.pathname.includes('reports')
  const isChatbotPage = location.pathname.includes('chatbot')

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    if (userUpdated && userUpdated.id) {
      setUserId(userUpdated.id)
    }
    setSelectedJobId(undefined)
  }, [])

  useEffect(() => {
    const handleSelectedJob = (jobId: string) => {
      setSelectedJobId(jobId);
    };
    const handleSelectedCompany = (companyId: string) => {
      setSelectedCompanyId(companyId);
    };
    const handleSelectedCollection = (collectionId: string) => {
      setSelectedCollectionId(collectionId);
    };
    const handleVerboseLogsEvent = (data: string) => {
      setVerboseLogs((prev) => [...prev, data]);
    };

    EventBus.subscribe('selected-job', handleSelectedJob);
    EventBus.subscribe('selected-company', handleSelectedCompany);
    EventBus.subscribe('selected-collection', handleSelectedCollection);
    EventBus.subscribe('verbose-logs', handleVerboseLogsEvent);

    return () => {
      EventBus.unsubscribe('selected-job', handleSelectedJob);
      EventBus.unsubscribe('selected-company', handleSelectedCompany);
      EventBus.unsubscribe('selected-collection', handleSelectedCollection);
      EventBus.unsubscribe('verbose-logs', handleVerboseLogsEvent);
    };
  }, []);

  useEffect(() => {
    if (!isReportPage && !isChatbotPage) {
      fetchAgents();
    }
  }, [pagination.page, searchTerm, sortBy, sortOrder]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing) return;
      if (drawerRef.current) {
        const drawerRect = drawerRef.current.getBoundingClientRect();
        const newWidth = Math.max(200, Math.min(e.clientX - drawerRect.left, drawerRect.width - 200));
        setVerbosePanelWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  const startResizing = () => {
    setIsResizing(true);
  };

  const fetchAgents = () => {
    setIsLoading(true);

    let url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=general`;

    if (isCompanyPage) {
      url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=company`;
    } else if (isCollectionPage) {
      url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=knowledge-base`;
    } else if (isDashboardPage) {
      url = `${apiUri}/get-agent?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}&tags=general`;
    }

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    if (sortBy && sortOrder) {
      url += `&sort=${sortBy}&order=${sortOrder}`;
    }

    axios.get(url)
      .then(response => {
        if (response.data.data) {
          setAgentArray(response.data.data);
          if (response.data.data.length > 0 && !selectedAgent) {
            setSelectedAgent(response.data.data[0]);
            EventBus.publish('selected-agent', response.data.data[0]);
          }
        }
        if (response.data.payload && response.data.payload.pagination) {
          setPagination((prev) => ({ ...prev, total: response.data.payload.pagination.total }));
        } else {
          setAgentArray([]);
        }
      })
      .catch(function (err) {
        console.log("Unable to fetch -", err);
      });

    setIsLoading(false);
  };

  const handleAgentSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const agent = agentArray.find(a => a.id === selectedId);
    setSelectedAgent(agent || null);
    EventBus.publish('selected-agent', agent || null);
  };

  const toggleVerbosePanel = () => {
    setIsVerbosePanelOpen(!isVerbosePanelOpen);
  };

  const clearChatHistory = async () => {
    try {
      const params: any = [];
      let url = `${apiUri}/start-new-chat-history?`;

      if (selectedAgent?.id) params.push(`agent_id=${selectedAgent.id}`);
      if (userId) params.push(`user_id=${userId}`);
      if (isCompanyPage && selectedCompanyId) params.push(`company_id=${selectedCompanyId}`);
      if (isCollectionPage && selectedCollectionId) params.push(`knowledge_base_id=${selectedCollectionId}`);
      if (isReportPage && selectedJobId) params.push(`job_id=${selectedJobId}`);

      url += params.join('&');

      const response = await axios.get(url);

      EventBus.publish('clear-chat-history', true)
    } catch (error) {
      console.error('Error clearing chat history:', error);
    }
  };

  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width={`{default:'${isVerbosePanelOpen ? '100vw' : '420px'}', 'md': '100vw'}`}
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
      style={{
        width: '100vw',
        height: '100vh',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      ref={drawerRef}
    >
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', height: '100%' }}>
        {isVerbosePanelOpen && (
          <>
            <div style={{ width: `${verbosePanelWidth}px`, height: '100%', overflow: 'auto' }}>
              <VerbosePanel verboseLogs={verboseLogs} setVerboseLogs={setVerboseLogs} />
            </div>
            <div
              ref={resizeRef}
              style={{
                width: '5px',
                cursor: 'col-resize',
                background: '#ddd',
                height: '100%'
              }}
              onMouseDown={startResizing}
            />
          </>
        )}
        <div style={{ flex: 1, height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <div className={`card w-100 rounded-0 border-0 ${isVerbosePanelOpen ? 'border-start' : ''}`} id='kt_drawer_chat_messenger'>
            <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
              <div className='card-title'>
                <button
                  className="btn btn-sm btn-icon btn-active-light-primary me-3"
                  onClick={toggleVerbosePanel}
                >
                  <KTIcon iconName={isVerbosePanelOpen ? 'arrow-right' : 'arrow-left'} className="fs-2" />
                </button>

                <div className="d-flex justify-content-center flex-column me-3">
                  <a href="#" className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                    {selectedAgent?.name ? selectedAgent.name : 'Chatbot'}
                  </a>

                  <div className="mb-0 lh-1">
                    <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                    <span className="fs-7 fw-bold text-gray-400">Active</span>
                  </div>
                </div>
              </div>

              <div className="card-toolbar">
                <div className="me-2">
                  <select
                    className="form-select form-select-sm"
                    onChange={handleAgentSelect}
                    value={selectedAgent?.id || ''}
                  >
                    <option value="">Select an agent</option>
                    {agentArray.map(agent => (
                      <option key={agent.id} value={agent.id}>{agent.name}</option>
                    ))}
                  </select>
                </div>

                <button
                  className="btn btn-sm btn-light-primary me-2"
                  onClick={clearChatHistory}
                >
                  <KTIcon iconName="trash" className="fs-2" />
                  Clear History
                </button>

                <div className="btn btn-sm btn-icon btn-active-light-primary ms-2" id="kt_drawer_chat_close">
                  <KTIcon iconName="cross" className="fs-2" />
                </div>
              </div>
            </div>

            <Chatbot
              isDrawer={true}
              noFooter={true}
              apiUrl={apiUri}
              selectedAgent={selectedAgent}
              personaName={selectedAgent?.name}
              personaAvatar={selectedAgent?.avatar} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DrawerMessenger;

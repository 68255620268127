import React, { useState, useRef, useEffect } from "react";
import { adjustTextareaHeight } from "../../utils";
import { ChatInputProps } from "../../types";

export const ChatInput: React.FC<ChatInputProps> = ({
  isDrawer,
  isLoadingMessage,
  setMessage,
  message,
  chatCompleted,
  onSubmit,
  inputColor,
  textareaHeight,
  setTextareaHeight,
}) => {
  const [hasContent, setHasContent] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // @ts-ignore // TODO: check
  const [allowUpload, setAllowUpload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!hasContent) {
      setTextareaHeight(40);
    }
  }, [hasContent]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setMessage(newValue);
    setHasContent(newValue.trim().length > 0);

    if (e.target.scrollHeight > e.target.clientHeight) {
      const newHeight = adjustTextareaHeight(e.target);
      setTextareaHeight(newHeight);
    }
  };

  const handleTextareaKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    } else if (e.key === "Enter") {
      const newHeight = adjustTextareaHeight(e.target as HTMLTextAreaElement);
      setTextareaHeight(newHeight);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim() || selectedFile) {
      onSubmit(e);
      setMessage("");
      setHasContent(false);
      setTextareaHeight(40);
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleSpeechRecognition = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Speech recognition is not supported in your browser. Please try Chrome.",
      );
      return;
    }

    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      setIsRecording(true);
    };

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      setMessage((prevMessage: any) => {
        const newMessage = (prevMessage + " " + transcript).trim();
        setHasContent(newMessage.length > 0);
        return newMessage;
      });
    };

    recognition.onerror = (event: any) => {
      console.error("Speech recognition error", event.error);
      setIsRecording(false);
    };

    recognition.onend = () => {
      setIsRecording(false);
    };

    recognition.start();
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type === "text/plain" || file.name.endsWith(".md")) {
        setSelectedFile(file);
        setDropdownOpen(false);
      } else {
        alert("Please upload only .txt or .md files.");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  const triggerFileInput = (e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="mt-auto">
      {selectedFile && (
        <div
          className="text-muted fs-7 position-absolute h-25px z-0"
          style={{ marginTop: "-20px" }}
        >
          {selectedFile.name}
        </div>
      )}

      <div
        className="row p-0 pt-4 mb-0 border-0"
        id={
          isDrawer
            ? "kt_drawer_chat_messenger_footer"
            : "kt_chat_messenger_footer"
        }
      >
        <div className="col-12 clear clearfix">
          <form onSubmit={handleSubmit}>
            <div
              className="d-flex flex-stack mx-2"
              style={{ marginBottom: "-42px" }}
            >
              <div className="d-flex align-items-center me-2 position-relative">
                <button
                  className="btn btn-sm btn-icon me-1 rounded-circle chat-icon"
                  type="button"
                  data-bs-toggle="tooltip"
                  title="Add attachment"
                  disabled={chatCompleted}
                  style={{ marginTop: "2px" }}
                  onClick={toggleDropdown}
                >
                  <i className="bi bi-plus fs-2 alt-white"></i>
                </button>
                {dropdownOpen && (
                  <div className="dropdown-menu show dropdown-menu-chat"
                    style={{
                      marginTop: `${allowUpload ? '0px' : '30px'}`,
                      background: `${inputColor ? inputColor : '#262626'}`
                    }}>
                    {allowUpload && (
                      <button
                        className="dropdown-item px-4"
                        onClick={triggerFileInput}
                        type="button"
                      >
                        <i className="bi bi-paperclip fs-2 alt-white pe-3"></i>
                        Upload File
                      </button>
                    )}
                    <button
                      className="dropdown-item px-4"
                      onClick={handleSpeechRecognition}
                      type="button"
                    >
                      <i className="bi bi-mic-fill fs-2 alt-white pe-3"></i>
                      Record Audio
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                  accept=".txt,.md"
                />
              </div>
            </div>
            <div className="">
              <textarea
                disabled={isLoadingMessage || chatCompleted}
                className={`${chatCompleted ? "chat-completed-input" : ""} chat-input form-control form-control-flush float-start px-16 rounded-xl`}
                rows={1}
                data-kt-element="input"
                placeholder={
                  isLoadingMessage
                    ? "Please wait..."
                    : chatCompleted
                      ? "Chat completed..."
                      : "Send a message"
                }
                value={message}
                onChange={handleInputChange}
                onKeyDown={handleTextareaKeyDown}
                style={{
                  height: `${textareaHeight}px`,
                  minHeight: "40px",
                  maxHeight: "120px",
                  width: "calc(100% - 40px)",
                  overflow: "hidden",
                  background: `${inputColor ? inputColor : '#262626'}`,
                }}
              ></textarea>
              <button
                className="btn btn-sm btn-icon ms-2 rounded-circle chat-icon"
                type="submit"
                data-bs-toggle="tooltip"
                title={
                  hasContent || selectedFile ? "Send Message" : "Record Voice"
                }
                style={{
                  width: "32px",
                  height: "32px",
                  marginTop: "8px",
                  paddingTop: "2px",
                }}
                disabled={chatCompleted || isRecording}
                onClick={hasContent || selectedFile ? onSubmit : handleSpeechRecognition}
              >
                <i
                  className={`bi ${hasContent || selectedFile ? "bi-arrow-bar-up" : `${isRecording ? "bi-headset" : "bi-headphones"}`} fs-2 alt-white`}
                ></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

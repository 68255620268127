/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useAuth, getAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import IPConfig from '../../../../app/store/IPConfig'
//import {Languages} from './Languages'

const HeaderUserMenu: FC = () => {
  const {logout} = useAuth();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    try {
      const user = getAuth()
      if (user) {
        setUser(user.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div
      className='menu-container menu menu-sub menu-sub-dropdown menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5' style={{ border: '4px solid var(--amaranth-purple)' }}>
            {user?.avatar ? (
              <img src={toAbsoluteUrl(IPConfig('public') + user.avatar)} alt="" />
            ) : (
              <img src={toAbsoluteUrl('/media/surge/avatar.jpg')} alt="" />
            )}
          </div>

          <div className='d-flex flex-column truncate text-ellipsis overflow-hidden'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.username}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      {/*<div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/account/overview'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>*/}

      {/*<Languages />*/}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

import React, {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {ScrollTop} from '../../../../../_metronic/layout/components/scroll-top'
import {Content} from '../../../../../_metronic/layout/components/content'
import {PageDataProvider} from '../../../../../_metronic/layout/core'
import {reInitMenu} from '../../../../../_metronic/helpers'

const ChatbotLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>

      <div className='d-flex flex-column flex-root app-root chat-background' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScrollTop />

    </PageDataProvider>
  )
}

export {ChatbotLayout}

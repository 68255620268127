import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import type { DetailedHTMLProps, ImgHTMLAttributes, HTMLAttributes } from 'react';

interface MessageContentRendererProps {
  content: string;
  publicUrl: string | undefined;
  isStreaming: boolean | undefined;
}

const MessageContentRenderer: React.FC<MessageContentRendererProps> = ({ content, publicUrl }) => {
  // Function to check if a string is a URL
  const isUrl = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  };

  // Function to check if URL is an image
  const isImageUrl = (url: string) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/i) != null || url.includes('/plotly_images/');
  };

  const processMessageContent = (text: string) => {
    const lines = text.split('\n');
    const processedLines = lines.map(line => {
      const words = line.split(' ');
      const processedWords = words.map(word => {
        if (isUrl(word) && isImageUrl(word)) {
          const imageUrl = word.startsWith('http')
            ? word
            : `${publicUrl}${word.startsWith('/') ? '' : '/'}${word}`;
          return `\n![Image](${imageUrl})\n`;
        }
        return word;
      });
      return processedWords.join(' ');
    });
    return processedLines.join('\n');
  };

  const components = {
    p: function Paragraph({
      node,
      children,
      ...props
    }: {
      node?: any;
      children?: React.ReactNode;
    } & DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) {
      // If the paragraph only contains an image, don't wrap it in extra margins
      if (React.Children.count(children) === 1 &&
        React.isValidElement(children) &&
        children.type === 'img') {
        return children;
      }
      return <p className="mb-2" {...props}>{children}</p>;
    },

    img: function Image({
      node,
      src,
      alt,
      ...props
    }: {
      node?: any;
      src?: string;
      alt?: string;
    } & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
      return (
        <div className="my-4">
          <a href={src} target="_blank">
            <img
              src={src}
              alt={alt}
              {...props}
              className="rounded max-w-full"
              style={{ maxHeight: '400px' }}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.style.display = 'none';
                console.error('Failed to load image:', src);
              }}
            />
          </a>
        </div>
      );
    }
  };

  const processedContent = processMessageContent(content);

  return (
    <div className="message-content">
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={components}
      >
        {processedContent}
      </Markdown>
    </div>
  );
};

export default MessageContentRenderer;
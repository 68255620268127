import React, { FC, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Chatbot, VerbosePanel } from '../../../libraries/react-chatbot/src/components'
import EventBus from '../../../libraries/react-chatbot/src/utils'

const ChatbotPage: FC = () => {
  const [token, setToken] = useState('')
  const [selectedPersonaName, setSelectedPersonaName] = useState('Chatbot')
  const [selectedPersonaAvatar, setSelectedPersonaAvatar] = useState('')
  const [verboseLogs, setVerboseLogs] = useState<string[]>([])
  const [isResizing, setIsResizing] = useState(false)
  const [verbosePanelWidth, setVerbosePanelWidth] = useState(400)

  const containerRef = useRef<HTMLDivElement>(null)
  const resizeRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  // Check if verbose mode is enabled via query param
  const urlParams = new URLSearchParams(window.location.search)
  const isVerboseMode = urlParams.get('verbose') === 'true'

  useEffect(() => {
    // Get token from query params
    const tokenFromParams = urlParams.get('token')
    if (tokenFromParams) {
      setToken(tokenFromParams)
    } else {
      navigate('/auth/login')
    }
  }, [])

  useEffect(() => {
    const handleVerboseLogsEvent = (data: string) => {
      setVerboseLogs((prev) => [...prev, data])
    }

    EventBus.subscribe('verbose-logs', handleVerboseLogsEvent)

    return () => {
      EventBus.unsubscribe('verbose-logs', handleVerboseLogsEvent)
    }
  }, [])

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing) return
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const newWidth = Math.max(200, Math.min(e.clientX - containerRect.left, containerRect.width - 400))
        setVerbosePanelWidth(newWidth)
      }
    }

    const handleMouseUp = () => {
      setIsResizing(false)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing])

  const startResizing = () => {
    setIsResizing(true)
  }

  if (!isVerboseMode) {
    return (
      <div className="d-flex flex-column flex-lg-row justify-content-center">
        <div className='flex-lg-row-fluid col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style={{ maxWidth: '1200px' }}>
          <Chatbot
            personaName={selectedPersonaName}
            personaAvatar={selectedPersonaAvatar}
            setPersonaName={setSelectedPersonaName}
            setPersonaAvatar={setSelectedPersonaAvatar}
            token={token}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className="d-flex flex-row w-100"
      style={{ height: '100vh', overflow: 'hidden' }}
      ref={containerRef}
    >
      <div style={{ width: `${verbosePanelWidth}px`, height: '100%', overflow: 'auto' }}>
        <VerbosePanel verboseLogs={verboseLogs} setVerboseLogs={setVerboseLogs} />
      </div>

      <div
        ref={resizeRef}
        style={{
          width: '5px',
          cursor: 'col-resize',
          background: '#ddd',
          height: '100%',
          marginLeft: '20px',
          marginRight: '20px'
        }}
        onMouseDown={startResizing}
      />

      <div style={{ flex: 1, height: '100%', overflow: 'hidden' }}>
        <div className="d-flex flex-column flex-lg-row justify-content-center h-100">
          <div className='flex-lg-row-fluid'>
            <Chatbot
              personaName={selectedPersonaName}
              personaAvatar={selectedPersonaAvatar}
              setPersonaName={setSelectedPersonaName}
              setPersonaAvatar={setSelectedPersonaAvatar}
              token={token}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { ChatbotPage }
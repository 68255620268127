/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import IPConfig from '../../store/IPConfig'
import { Spinner } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import clsx from 'clsx'
import Chatbot from '../../../libraries/react-chatbot/src/components/Chatbot'
import { getAuth } from '../auth'
import axios from 'axios'
import EventBus from '../../../libraries/react-chatbot/src/utils'

const Reports: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isChatEnabled, setIsChatEnabled] = useState(false)
  const [scoreContent, setScoreContent]: any = useState(null)
  const [exportContent, setExportContent]: any = useState(null)
  const [reports, setReports]: any = useState<[]>([])
  const [userId, setUserId] = useState<any>(null)
  const [initialReportLoaded, setInitialReportLoaded] = useState(false);

  const [token, setToken] = useState('')
  const [reportStatus, setReportStatus] = useState('')
  const [cancelledNote, setCancelledNote] = useState("no reason")
  const [documentReportId, setDocumentReportId] = useState(null)
  const [documentScoreId, setDocumentScoreId] = useState(null)
  const [selectedReportName, setSelectedReportName] = useState('Pitch Readiness Report')
  const [selectedSourceId, setSelectedSourceId] = useState(null)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [selectedPersonaName, setSelectedPersonaName] = useState('Chatbot')
  const [selectedPersonaAvatar, setSelectedPersonaAvatar] = useState('')

  const publicUrl = IPConfig('public')
  const altApiUrl = IPConfig('metis')
  const apiUrl = IPConfig()

  useEffect(() => {
    const user = getAuth()
    if (user) {
      setUserId(user.data.id)
      setToken(user.data.auth_token)
    }

    const body = document.getElementById('kt_app_body')
    if (body) {
      body.style.backgroundColor = '#FFFFFF'
    }

    initializeEventListeners()
    fetchReports()
    setIsLoading(true)

    return () => {
      if (body) {
        body.style.backgroundColor = '#FFFFFF'
      }
      removeEventListeners()
    }
  }, [])

  useEffect(() => {
    fetchChatbotEnabled();
  }, [selectedPersonaName])

  const initializeEventListeners = () => {
    EventBus.subscribe('selected-report-content', handleSelectedReportContent)
    EventBus.subscribe('selected-score-content', handleSelectedScoreContent)
    EventBus.subscribe('selected-report-name', handleSelectedReportName)
    EventBus.subscribe('selected-source-id', handleSelectedSourceId)
    EventBus.subscribe('selected-job', handleSelectedJob)
    EventBus.subscribe('selected-persona-name', handleSelectedPersonaName)
    EventBus.subscribe('selected-persona-avatar', handleSelectedPersonaAvatar)
    EventBus.subscribe('loading-report', handleLoadingReport)
    EventBus.subscribe('chat-enabled', handleChatEnabled)
    EventBus.subscribe('chat-disabled', handleChatDisabled)
    EventBus.subscribe('report-status', handleReportStatus)
    EventBus.subscribe('cancelled-note', handleCancelledNote)
  }

  const removeEventListeners = () => {
    EventBus.unsubscribe('selected-report-content', handleSelectedReportContent)
    EventBus.unsubscribe('selected-score-content', handleSelectedScoreContent)
    EventBus.unsubscribe('selected-report-name', handleSelectedReportName)
    EventBus.unsubscribe('selected-source-id', handleSelectedSourceId)
    EventBus.unsubscribe('selected-job', handleSelectedJob)
    EventBus.unsubscribe('selected-persona-name', handleSelectedPersonaName)
    EventBus.unsubscribe('selected-persona-avatar', handleSelectedPersonaAvatar)
    EventBus.unsubscribe('loading-report', handleLoadingReport)
    EventBus.unsubscribe('chat-enabled', handleChatEnabled)
    EventBus.unsubscribe('chat-disabled', handleChatDisabled)
    EventBus.unsubscribe('report-status', handleReportStatus)
    EventBus.unsubscribe('cancelled-note', handleCancelledNote)
  }

  const handleSelectedReportContent = (content) => {
    setDocumentReportId(content)
    setExportContent(content)
    setIsLoading(false)
    fetchFile()
  }

  const handleSelectedScoreContent = (content) => {
    setDocumentScoreId(content)
    setScoreContent(content)
  }

  const handleSelectedReportName = (name) => setSelectedReportName(name || 'Pitch Readiness Report')
  const handleSelectedSourceId = (id) => setSelectedSourceId(id)
  const handleSelectedJob = (jobId) => setSelectedJobId(jobId)
  const handleLoadingReport = () => setIsLoading(true)
  const handleChatEnabled = () => setIsChatEnabled(true)
  const handleChatDisabled = () => setIsChatEnabled(false)
  const handleReportStatus = (status) => setReportStatus(status)
  const handleCancelledNote = (note) => setCancelledNote(note || 'no reason provided')
  const handleSelectedPersonaAvatar = (avatar) => setSelectedPersonaAvatar(avatar)
  const handleSelectedPersonaName = (name) => {
    setSelectedPersonaName(name || 'Chatbot')
    fetchChatbotEnabled()
  }

  const fetchFile = async () => {
    setIsLoading(true);

    try {
      if (!reports) {
        setIsLoading(false);
        return;
      }

      const getDocumentContent = async (fileId: string) => {
        const response = await axios.get(`${apiUrl}/export?file_id=${fileId}&token=${token}`);
        return response.data;
      };

      if (documentReportId) {
        // Fetch specific report file
        setExportContent(await getDocumentContent(documentReportId));

        if (documentScoreId) {
          setScoreContent(await getDocumentContent(documentScoreId));
        }
      } else if (reports.length > 0) {
        const report = reports[0];

        if (report.saved_documents?.[0]?.id && report.saved_documents[0].primary) {
          // Fetch primary document
          setExportContent(await getDocumentContent(report.saved_documents[0].id));

          if (report.saved_documents?.[1]?.id) {
            // Fetch secondary document if available
            setScoreContent(await getDocumentContent(report.saved_documents[1].id));
          }
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching file:', error);
      setIsLoading(false);
    }
  };

  const fetchReports = async () => {
    setIsLoading(true);

    if (!userId) {
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/get-report?user_id=${userId}`);

      if (response.data?.data) {
        setReports(response.data.data);
        if (response.data.data.length > 0) {
          const initialReport = response.data.data[0];
          setSelectedJobId(initialReport.id);
          setSelectedPersonaName(initialReport.input_variables?.agent_persona || 'Chatbot');
          setSelectedPersonaAvatar(initialReport.input_variables?.agent_avatar || '');
          setInitialReportLoaded(true);
        }
      } else {
        setReports(null);
      }

    } catch (error) {
      console.error('Unable to fetch reports:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChatbotEnabled = async () => {
    if (selectedPersonaName !== "Chatbot") {
      try {
        const response = await axios.get(`${altApiUrl}/chatbot-check`, {
          params: { persona_name: selectedPersonaName }
        });

        if (response.data?.data) {
          setIsChatEnabled(response.data.data);
        } else {
          setIsChatEnabled(false);
        }
      } catch (error) {
        console.error('Unable to fetch chatbot status:', error);
      }
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row">
      <div className={clsx('flex-column flex-lg-row-auto mb-10 mb-lg-0', {
        'col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7': isChatEnabled,
        'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12': !isChatEnabled || reportStatus === 'cancelled',
      })}>
        <div className="card card-flush min-height-container alt-bg-gunmetal alt-border-gunmetal-light">
          <div
            className="card-body pt-5"
            id="kt_chat_contacts_body"
            style={{height: `calc(100vh)`, minHeight: `calc(100vh)`}}
          >

            {reportStatus === 'cancelled' && (
              <div className="text-center pt-10 alt-white">
                <h2 className="mt-10">Your job has been cancelled.</h2>
                <p>The reason for cancellation is {cancelledNote}. If you think this is a mistake, please contact support at <a href='mailto:info@altsurge.ai'>info@altsurge.ai</a> </p>
              </div>
            )}

            {isLoading && reportStatus !== 'cancelled' && (
              <div className="text-center pt-10 alt-white">
                <Spinner animation="border" role="status" className="d-flex justify-content-center h-80px w-80px loader-align">
                  <span className="sr-only">Loading...</span>
                </Spinner>

                <h2 className="mt-10">We are still generating your report</h2>
                <p>An email will be sent to your registered email when it’s done</p>
              </div>
            )}

            {!isLoading && reportStatus !== 'cancelled' && (
              <div
                className="scroll-y me-n5 pe-5 h-lg-auto"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
                data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body"
                data-kt-scroll-offset="0px"
                style={{height: `calc(100vh - 40px)`, minHeight: `calc(100vh - 40px)`}}
              >

                <div className="templates row alt-white">
                  <div className="template_head col-12">
                    {(exportContent && scoreContent) && (
                      <iframe
                        src={`${apiUrl}/export?file_id=${exportContent}&score_file_id=${scoreContent}&token=${token}&embedded=true#navpanes=0`}
                        height="100%"
                        width="100%"
                        style={{height: `calc(100vh - 40px)`}}>
                      </iframe>
                    )}

                    {(exportContent && !scoreContent) && (
                      <iframe src={`${apiUrl}/export?file_id=${exportContent}&token=${token}&embedded=true#navpanes=0`}
                        height="100%"
                        width="100%"
                        style={{height: `calc(100vh - 40px)`}}>
                      </iframe>
                    )}
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
      </div>

      <div className={clsx('flex-lg-row-fluid col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 ms-lg-7 ms-xl-10', {
        'd-none': !isChatEnabled || reportStatus === 'cancelled',
      })}>
        <div className="card min-height-container alt-bg-gunmetal alt-border-gunmetal-light" id="kt_chat_messenger">
          <div className="card-header" id="kt_chat_messenger_header">
            <div className="card-title">
              <div className="symbol-group symbol-hover"></div>
              <div className="symbol symbol-50px symbol-circle" style={{marginLeft: `-15px`}}>
                <img alt={selectedPersonaName} src={selectedPersonaAvatar ? `${publicUrl}/${selectedPersonaAvatar}` : toAbsoluteUrl(`/media/surge/avatar.jpg`)} />
              </div>
              <div className="d-flex justify-content-center flex-column ms-3 me-3">
                <a
                  href="#"
                  className="fs-4 fw-bolder alt-white text-hover-primary me-1 mb-2 lh-1"
                >
                  {selectedPersonaName}
                </a>

                <div className="mb-0 lh-1">
                  <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                  <span className="fs-7 fw-bold alt-white">Active</span>
                </div>
              </div>
            </div>
          </div>

          <Chatbot
            noFooter={true}
            bgColor={'#0e252f'}
            inputColor={'#12303d'}
            isChatEnabled={isChatEnabled}
            personaName={selectedPersonaName}
            personaAvatar={selectedPersonaAvatar}
            apiUrl={IPConfig('metis')}
            endpoint="chatbot-chat"
            token={token} />
        </div>
      </div>
    </div>
  )
}

export {Reports}

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ChatMessageProps } from "../../types";
import MessageContentRenderer from "./MessageContentRenderer";

export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  index,
  isDrawer,
  personaName,
  personaAvatar,
  userName,
  publicUrl,
  onLike,
  onDislike,
  onReadAloud,
  onCopy,
  inputColor,
  streamingMessage,
}) => {
  const [displayedText, setDisplayedText] = useState<string>("");

  useEffect(() => {
    try {
      setDisplayedText(message.text);
    } catch (exception) {
      console.error(exception);
    }
  }, [message.text, message.isStreaming]);

  const contentClass = `${isDrawer ? "" : "d-flex"} justify-content-${
    message.sender === "bot" ? "start" : "end"
  } mb-10`;

  return (
    <div
      key={`message${index}`}
      id={`message-${index}`}
      className={clsx("d-flex", contentClass, "mb-10", {
        "d-none": message.template,
      })}
    >
      <div
        className={clsx(
          "d-flex flex-column align-items chat-wrapper w-100",
          `align-items-${message.sender === "bot" ? "start" : "end"}`
        )}
      >
        <div
          className={clsx(
            "d-flex align-items-center",
            `${message.sender === "bot" ? "mb-0" : "mb-2"}`
          )}
        >
          {message.sender === "bot" ? (
            <>
              <div className="symbol symbol-35px symbol-circle">
                <img
                  alt={personaName}
                  src={
                    personaAvatar
                      ? `${publicUrl}/${personaAvatar}`
                      : `${publicUrl + '/media/avatars/blank.png'}`
                  }
                />
              </div>
              <div className="ms-3">
                <a
                  href="#"
                  className="fs-5 fw-bolder alt-white text-hover-primary me-1"
                >
                  {personaName}
                </a>
                <span className="text-muted fs-7 mb-1">
                  {message.time
                    ? new Date(message.time)
                      .toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .replace(/,/g, '')
                    : 'N/A'}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="me-3">
                <span className="text-muted fs-7 mb-1">
                  {message.time
                    ? new Date(message.time)
                      .toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .replace(/,/g, '')
                    : 'N/A'}
                </span>
                <a
                  href="#"
                  className="fs-5 fw-bolder alt-white text-hover-primary ms-1"
                >
                  {userName}
                </a>
              </div>
              <div className="symbol symbol-35px symbol-circle">
                <img alt="Pic" src={`${publicUrl}/media/avatars/blank.png`} />
              </div>
            </>
          )}
        </div>

        <div
          className={clsx(
            "px-5 pb-0 font-size-all markdown chat-message position-relative w-auto",
            `${message.sender === "bot" ? "ps-14 pt-0" : "pt-3"}`
          )}
          style={{
            marginTop: `${message.sender === "bot" ? "-5px" : "0px"}`,
            background: `${message.sender !== "bot" ? (inputColor ? inputColor : '#262626') : "transparent"}`,
            overflowWrap: 'break-word', // Enable word wrapping
            wordBreak: 'break-word', // Allow breaking words if necessary
            hyphens: 'auto', // Enable hyphenation
            maxWidth: '100%' // Ensure the content doesn't exceed the container width
          }}
          data-kt-element="message-text"
        >
          <MessageContentRenderer
            content={displayedText}
            publicUrl={publicUrl}
            isStreaming={message.isStreaming}
          />

          {message.sender === "bot" && (
            <div className="message-actions">
              <button
                className="action-icon"
                title="Like"
                onClick={() => onLike(index)}
              >
                <i className="bi bi-hand-thumbs-up-fill fs-3"></i>
              </button>
              <button
                className="action-icon"
                title="Dislike"
                onClick={() => onDislike(index)}
              >
                <i className="bi bi-hand-thumbs-down-fill fs-3"></i>
              </button>
              <button
                className="action-icon"
                title="Read Aloud"
                onClick={() => onReadAloud(displayedText)}
              >
                <i className="bi bi-volume-up-fill fs-3"></i>
              </button>
              <button
                className="action-icon"
                title="Copy"
                onClick={() => onCopy(displayedText)}
              >
                <i className="bi bi-clipboard-fill fs-3"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
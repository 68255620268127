class EventBus {
  private events: Map<string, ((data: any) => void)[]> = new Map();

  subscribe(eventName: string, callback: (data: any) => void) {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, []);
    }
    this.events.get(eventName)?.push(callback);
  }

  unsubscribe(eventName: string, callback: (data: any) => void) {
    if (this.events.has(eventName)) {
      this.events.set(
        eventName,
        this.events.get(eventName)?.filter((cb) => cb !== callback) ?? []
      );
    }
  }

  publish(eventName: string, data: any) {
    if (this.events.has(eventName)) {
      this.events.get(eventName)?.forEach((callback) => callback(data));
    }
  }
}

export default new EventBus();